{
    "welcome": "Bonjour, je m'appelle",
    "welcome-portfolio": "Bienvenue dans mon portfolio",
    "know-more-btn": "En savoir plus",
    "pageTitle": "Aloïs Fournier | Développeur",
    "aboutSectionTitle": "à propos de moi",
    "aboutInfoText1": "Etudiant en seconde année à l'ESIEE Paris à la recherche d'une alternance en vue de mon entrée dans le cursus:",
    "aboutInfoText2": "Passionné d'informatique, de musique et de bricolage, vous trouverez ci-dessous mon CV et mes différents projets aboutis ou non.",
    "cursus": "Informatique & Applications",
    "aboutCTABtnText": "Curriculum Vitæ",
    "projectsSectionTitle": "Mes Projets",
    "project1Title": "T'ô Sec",
    "project1Description1": "Le projet le plus ambitieux et important auquel j'ai participé:",
    "project1Description2": "Il s'agit d'un service proposant la location gratuite de parapluies en partenariat avec des commerçants volontaires de notre réseau.",
    "project1Description3": "Toujours en activité, ce projet m'a permis d'apprendre",
    "project1Description4": "ainsi que de renforcer mes connaissances dans une myriade d'autre languages et services.",
    "project1WebsiteBtnText": "Site Web",
    "project2Title": "ICSFE Notifier & ICSBE Sender",
    "project2Description1": "Petit projet fait durant mon temps libre pendant les vacances hivernales de février 2024, ICSBE lit et trie un fichier .ICAL afin d'envoyer une notification aux clients ICSFE pour savoir dans quelle salle se trouvent les élèves concernés.",
    "project2Description2": " Inspiré de ",
    "project2ICSFEBtnText": "ICSFE",
    "project2ICSBEBtnText": "ICSBE",
    "project3Title": "Crypt.Systems",
    "project3Description": "Crypt Systems (Anciennement DreamingMind) contient mon site web personnel, il a contenu des services hostés par mon serveur qui sont maintenant hors-ligne car remplacés par la partie serveur plus performante de",
    "project3WebsiteBtnText": "crypt.systems",
    "contactSectionTitle": "Contact",
    "contactText": "Contactez-moi !",
    "contactEmail": "alois.fournier@edu.esiee.fr",
    "footerBackToTopTitle": "Haut de page"
}