import initScrollReveal from "./scripts/scrollReveal";
import initTiltEffect from "./scripts/tiltAnimation";
import { targetElements, defaultProps } from "./data/scrollRevealConfig";
import i18next from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from './translations/en.json';
import frTranslation from './translations/fr.json';

initScrollReveal(targetElements, defaultProps);
initTiltEffect();

i18next.use(LanguageDetector).init({
    fallbackLng: 'en',
    detection: {
        order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag']
    },
    debug: true,
    resources: {
        en: {
            translation: enTranslation,
        },
        fr: {
            translation: frTranslation,
        }
    }
});

console.log(i18next.getDataByLanguage('en'));

document.addEventListener('DOMContentLoaded', () => {
    let elements = document.querySelectorAll('[data-i18n]');
    elements.forEach(element => {
        let key = element.getAttribute('data-i18n');
        element.textContent = i18next.t(key);
    });
});
