{
  "welcome": "Hello, my name is",
  "welcome-portfolio": "Welcome to my portfolio",
  "know-more-btn": "Know More",
  "pageTitle": "Aloïs Fournier | Developer",
  "aboutSectionTitle": "About Me",
  "aboutInfoText1": "Second-year student at ESIEE Paris looking for an apprenticeship for my entry into the cursus:",
  "aboutInfoText2": "Passionate about computer science, music, and DIY, you will find below my CV and my various completed or ongoing projects.",
  "cursus": "Computers & Applications",
  "aboutCTABtnText": "Curriculum Vitæ",
  "projectsSectionTitle": "My Projects",
  "project1Title": "T'ô Sec",
  "project1Description1": "The most ambitious and important project I have participated in:",
  "project1Description2": "It is a service offering free umbrella rental in partnership with willing merchants in our network.",
  "project1Description3": "Still in operation, this project allowed me to learn",
  "project1Description4": " as well as strengthen my knowledge in a myriad of other languages and services.",
  "project1WebsiteBtnText": "Website",
  "project2Title": "ICSFE Notifier & ICSBE Sender",
  "project2Description1": "Small project done during my free time during the winter holidays of February 2024, ICSBE reads and sorts a .ICAL file to send notifications to ICSFE clients to know in which room the concerned students are.",
  "project2Description2": "Inspired by ",
  "project2ICSFEBtnText": "ICSFE",
  "project2ICSBEBtnText": "ICSBE",
  "project3Title": "Crypt.Systems",
  "project3Description": "Crypt Systems (formerly DreamingMind) is my personal website, it contained different services hosted by my VPS which were subsided by the backend of",
  "project3WebsiteBtnText": "crypt.systems",
  "contactSectionTitle": "Contact",
  "contactText": "Contact me !",
  "contactEmail": "alois.fournier@edu.esiee.fr"
}